import { defineStore } from "pinia";
import type { MqttClient } from "mqtt";
import mqtt from "mqtt";
import { ref } from "vue";
import type { Message } from "@/modules/deal/queries/ChatQuery";

export type MqttResponse = {
  topic: string;
  message: Message;
};

export const useMqttStore = defineStore("mqtt", () => {
  const mqttClient = ref<MqttClient | null>(null);
  const mqttMessage = ref<Message>({} as Message);
  const mqttResponse = ref<MqttResponse>({} as MqttResponse);
  const mqttURL = `${import.meta.env.VITE_APP_MQTT_HOST}:${import.meta.env.VITE_APP_MQTT_PORT}`;

  const initializeMqttClient = () => {
    mqttClient.value = mqtt.connect(`wss://${mqttURL}/mqtt`, {
      clientId: "vue-app-" + Math.random().toString(16).substr(2, 8),
      username: import.meta.env.VITE_APP_MQTT_USER,
      password: atob(import.meta.env.VITE_APP_MQTT_KEY),
    });

    mqttClient.value.on("connect", () => {
      if (import.meta.env.VITE_APP_ENVIRONMENT !== "production") {
        console.log("Connected...");
      }
    });

    mqttClient.value.on("message", (topic, message) => {
      mqttResponse.value.topic = topic;
      mqttResponse.value.message = JSON.parse(message.toString()) as Message;
    });

    mqttClient.value.on("error", (err) => {
      if (import.meta.env.VITE_APP_ENVIRONMENT !== "production") {
        console.error("MQTT error:", err);
      }
    });
  };

  const subscribeToTopic = (topic: string) => {
    if (mqttClient.value) {
      mqttClient.value.subscribe(topic, (err) => {
        if (import.meta.env.VITE_APP_ENVIRONMENT !== "production") {
          if (!err) {
            console.log(`Subscribed to topic: ${topic}`);
          } else {
            console.error(`Failed to subscribe to topic: ${topic}`, err);
          }
        }
      });
    }
  };

  const unsubscribeToTopic = (topic: string) => {
    if (mqttClient.value) {
      mqttClient.value.unsubscribe(topic, (error) => {
        if (error) {
          if (import.meta.env.VITE_APP_ENVIRONMENT !== "production") {
            console.error(`Failed to unsubscribe to topic: ${topic}`, error);
          }
          return;
        }
      });
      if (import.meta.env.VITE_APP_ENVIRONMENT !== "production") {
        console.log(`unsubscribed topic: ${topic}`);
      }
    }
  };

  const publishMessage = (payload: string) => {
    if (mqttClient.value) {
      mqttClient.value.publish("deal/#", payload);
    }
  };

  const closeConnection = () => {
    if (mqttClient.value) {
      mqttClient.value.end();
    }
  };

  return {
    initializeMqttClient,
    publishMessage,
    closeConnection,
    subscribeToTopic,
    unsubscribeToTopic,
    mqttResponse,
  };
});
