<script lang="ts" setup>
import { type PropType } from "vue";
import { useRoute } from "vue-router";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronRightIcon } from "@heroicons/vue/20/solid";

export type Child = {
  name: string;
  icon: unknown;
  to: string;
  current: boolean;
};

export type NavigationElementDetail = {
  name: string;
  to?: string;
  icon: unknown;
  current: boolean;
  type: string;
  children?: Child[];
};

defineOptions({
  name: "NavigationElement",
});

const emit = defineEmits(["action"]);

const route = useRoute();

defineProps({
  element: {
    type: Object as PropType<NavigationElementDetail>,
    required: true,
  },
  collapsed: {
    type: Boolean,
    default: false,
  },
});

function onAction(action: string | undefined) {
  emit("action", action);
}
</script>

<template>
  <div class="navigation-element">
    <div v-if="element.type === 'button'">
      <button :class="['menu-element', { current: element.current }]" @click="onAction(element.to)">
        <component :is="element.icon" class="navigation-icon" />
        <span v-if="!collapsed" class="navigation-text">
          {{ element.name }}
        </span>
      </button>
    </div>
    <div v-if="element.type === 'link'">
      <router-link
        v-if="!element.children"
        :class="{ current: element.to === route.name }"
        :to="{ name: element.to }"
        class="menu-element"
      >
        <component :is="element.icon" class="navigation-icon" />
        <span v-if="!collapsed" class="navigation-text">
          {{ element.name }}
        </span>
      </router-link>
      <Disclosure v-else v-slot="{ open }" as="div" class="group">
        <DisclosureButton
          :class="[element.current ? 'current' : '', 'disclosure-button group-hover:bg-jordy-700']"
        >
          <component
            :is="element.icon"
            aria-hidden="true"
            class="group-hover:text-white h-6 w-6 shrink-0 text-jordy-700"
          />
          <span v-if="!collapsed" class="group-hover:text-white text-jordy-700">
            {{ element.name }}
          </span>
          <ChevronRightIcon
            v-if="!collapsed"
            :class="[
              open ? 'current' : ' text-jordy-700',
              'ml-auto h-5 w-5 shrink-0 group-hover:text-white',
            ]"
            aria-hidden="true"
          />
        </DisclosureButton>
        <DisclosurePanel as="ul" class="mt-1 px-2 space-y-1">
          <li v-for="subItem in element.children" :key="subItem.name">
            <router-link
              :class="{ current: subItem.to === route.name }"
              :to="{ name: subItem.to }"
              class="navigation-element-submenu group"
            >
              <span class="flex items-center gap-2 group-hover:text-white">
                <component :is="subItem.icon" class="navigation-icon" />
                <span v-if="!collapsed" class="navigation-text">{{ subItem.name }}</span>
              </span>
            </router-link>
          </li>
        </DisclosurePanel>
      </Disclosure>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.navigation-element {
  .disclosure-button {
    @apply transition duration-500 ease-in-out rounded-full flex w-full items-center gap-x-4 py-2 px-3 text-left font-medium text-jordy-700;
  }

  &-submenu {
    @apply mt-1 hover:bg-blue-700 transition duration-500 ease-in-out block rounded-full py-2 pl-5 pr-2 text-sm leading-6 text-gray-700;

    .navigation-icon {
      @apply h-5 w-5 text-jordy-700 transition duration-500 ease-in-out;
    }

    .navigation-text {
      @apply font-medium text-jordy-700 transition duration-500 ease-in-out;
    }

    &:hover {
      @apply bg-sunburst-300 text-white;

      .navigation-icon,
      .navigation-text {
        @apply text-white;
      }
    }

    &.current {
      @apply bg-sunburst-300;

      .navigation-icon,
      .navigation-text {
        @apply text-white;
      }
    }
  }

  .menu-element {
    @apply flex items-center w-full transition duration-500 ease-in-out py-2 px-3 rounded-full;

    .navigation-icon {
      @apply w-6 h-6 text-jordy-700 mr-4 transition duration-500 ease-in-out;
    }

    .navigation-text {
      @apply font-medium text-jordy-700 transition duration-500 ease-in-out;
    }

    &:hover {
      @apply bg-sunburst-300 text-white;

      .navigation-icon,
      .navigation-text {
        @apply text-white;
      }
    }

    &.current {
      @apply bg-jordy-700;

      .navigation-icon,
      .navigation-text {
        @apply text-white;
      }
    }
  }
}
</style>
