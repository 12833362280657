<script lang="ts" setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import TopFluenzIcon from "@/components/icons/TopFluenzIcon.vue";
import NavigationElement from "@/components/core/navigation/NavigationElement.vue";
import { useNavigationHelper } from "@/components/core/navigation/composable/useNavigationHelper";

defineOptions({
  name: "NavigationDrawer",
});

const sidebarOpen = defineModel<boolean>({ required: true });

const { navigation } = useNavigationHelper();

function closeSidebar() {
  sidebarOpen.value = false;
}

function handleNavigationAction() {
  sidebarOpen.value = false;
}
</script>

<template>
  <TransitionRoot :show="sidebarOpen" as="template">
    <Dialog class="relative z-50" @close="closeSidebar">
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900/80" />
      </TransitionChild>

      <div class="fixed inset-0 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                <button class="-m-2.5 p-2.5" type="button" @click="closeSidebar">
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon aria-hidden="true" class="size-6 text-white" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
              <div class="flex h-auto shrink-0 items-center">
                <TopFluenzIcon class="w-28" />
              </div>
              <nav class="navigation-sidebar-menu">
                <ul class="flex flex-1 flex-col gap-y-3 mb-2" role="list">
                  <li v-for="item in navigation" :key="item.name">
                    <NavigationElement :element="item" @action="handleNavigationAction" />
                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style lang="scss" scoped></style>
