import { ref } from "vue";
import { defineStore } from "pinia";

type ToastType = "success" | "error" | "warning" | "info";

interface ToastOptions {
  id: number;
  message: string;
  type: ToastType;
  duration: number;
  visible?: boolean;
}

export const useToastStore = defineStore("toastStore", () => {
  const toasts = ref<ToastOptions[]>([]);

  const showToast = (toast: ToastOptions) => {
    toast.id = Date.now();
    toast.visible = true;
    toasts.value.push(toast);

    if (toast.duration) {
      setTimeout(() => {
        hideToast(toast.id);
      }, toast.duration);
    }
  };

  const hideToast = (id: number) => {
    const toast = toasts.value.find((toast) => toast.id === id);
    if (toast) {
      toast.visible = false;

      // Remove the toast from the store after the fade-out animation
      setTimeout(() => {
        removeToast(id);
      }, 500); // Match this duration with the CSS transition duration
    }
  };

  const removeToast = (id: number) => {
    toasts.value = toasts.value.filter((toast: ToastOptions) => toast.id !== id);
  };

  const info = (message: string) => {
    showToast({
      id: 0,
      message: message,
      type: "info",
      duration: 5000,
    });
  };

  const error = (message: string) => {
    showToast({
      id: 0,
      message: message,
      type: "error",
      duration: 5000,
    });
  };

  const success = (message: string) => {
    showToast({
      id: 0,
      message: message,
      type: "success",
      duration: 5000,
    });
  };

  const warn = (message: string) => {
    showToast({
      id: 0,
      message: message,
      type: "warning",
      duration: 5000,
    });
  };

  return {
    toasts,
    showToast,
    removeToast,
    info,
    error,
    success,
    warn,
  };
});
