<script lang="ts" setup>
import useAccountHandler from "@/helpers/useAccountHandler";
import { computed, onMounted } from "vue";
import { KzAvatar, KzFlyout } from "@keyfluenz/vue-core";

defineOptions({
  name: "NavigationBar",
});

const { accountInfo, getInfo, logout } = useAccountHandler();

onMounted(async () => {
  if (!accountInfo.value) {
    await getInfo();
  }
});

const logOut = [{ name: "Log Out" }];

const nameInfo = computed((): string => {
  const profileImage = accountInfo.value?.profile_picture_image;
  const firstName = accountInfo.value?.first_name;

  return profileImage && profileImage.trim() !== "" ? profileImage : firstName || "A";
});
</script>

<template>
  <div
    class="sticky top-0 z-40 flex items-center justify-between gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6"
  >
    <slot name="button" />
    <KzFlyout data-testid="flyout-avatar-navbar">
      <template #button>
        <div class="flex space-x-4 items-start max-w-52 group">
          <KzAvatar :name="nameInfo" />
        </div>
      </template>
      <template #items>
        <button
          v-for="item in logOut"
          :key="item.name"
          class="p-2 hover:text-blue-600"
          @click="logout"
        >
          {{ item.name }}
        </button>
      </template>
    </KzFlyout>
  </div>
</template>

<style lang="scss" scoped>
:deep(.flyout__panel) {
  @apply -translate-x-64 translate-y-14;
}
</style>
