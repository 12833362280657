import {
  BanknotesIcon,
  BriefcaseIcon,
  ChartPieIcon,
  ClipboardDocumentListIcon,
  CogIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  DocumentPlusIcon,
  FolderOpenIcon,
  RectangleGroupIcon,
  RectangleStackIcon,
  SquaresPlusIcon,
  SwatchIcon,
  UserIcon,
} from "@heroicons/vue/24/outline";
import { useI18n } from "vue-i18n";
import type { NavigationElementDetail } from "@/components/core/navigation/NavigationElement.vue";

export const useNavigationHelper = () => {
  const { t } = useI18n();

  const navigation = [
    {
      name: t("common.sidebar.dashboard"),
      to: "Dashboard",
      icon: ChartPieIcon,
      current: true,
      type: "link",
    },
    {
      name: t("common.sidebar.task"),
      icon: BriefcaseIcon,
      current: false,
      type: "link",
      children: [
        {
          name: t("common.sidebar.campaign"),
          icon: DocumentPlusIcon,
          to: "CampaignCreationType",
          current: false,
        },
        {
          name: t("common.sidebar.campaignList"),
          icon: ClipboardDocumentListIcon,
          to: "CampaignListing",
          current: false,
        },
        {
          name: t("common.sidebar.deals"),
          icon: CurrencyDollarIcon,
          to: "TheDeals",
          current: false,
        },
        {
          name: t("common.sidebar.content"),
          icon: SquaresPlusIcon,
          to: "ContentListing",
          current: false,
        },
      ],
    },
    {
      name: t("common.sidebar.profile"),
      icon: UserIcon,
      current: false,
      type: "link",
      children: [
        {
          name: t("common.sidebar.settings"),
          icon: CogIcon,
          to: "AccountOverview",
          current: false,
        },
        {
          name: t("common.sidebar.brands"),
          icon: RectangleGroupIcon,
          to: "BrandListing",
          current: false,
        },
        {
          name: t("common.sidebar.products"),
          icon: SwatchIcon,
          to: "Products",
          current: false,
        },
      ],
    },
    {
      name: t("common.sidebar.balance"),
      icon: FolderOpenIcon,
      current: false,
      type: "link",
      children: [
        {
          name: t("common.sidebar.wallet"),
          icon: RectangleStackIcon,
          to: "Wallet",
          current: false,
        },
        {
          name: t("common.sidebar.billing"),
          icon: BanknotesIcon,
          to: "Billing",
          current: false,
        },
        {
          name: t("common.sidebar.payment"),
          icon: CreditCardIcon,
          to: "PaymentMethods",
          current: false,
        },
      ],
    },
  ] as NavigationElementDetail[];

  return {
    navigation,
  };
};
