import { ref } from "vue";
import AuthService from "./AuthService";
import { provideApolloClient, useApolloClient } from "@vue/apollo-composable";
import AccountQuery from "@/modules/authentication/queries/AccountQuery";
import authenticatedService from "@/services/AuthenticatedService";
import type { CompanyDataType } from "@/types/AccountTypes";
import { useRouter } from "vue-router";
import type { Company } from "@/modules/campaign/queries/CampaignQuery.types";

const accountInfo = ref<Company | null>(null);

export default function useAccountHandler() {
  const router = useRouter();

  const getInfo = async (update = false) => {
    if (!accountInfo.value || update) {
      provideApolloClient(authenticatedService);
      const { resolveClient } = useApolloClient();
      const client = resolveClient();
      const { data } = await client.query<CompanyDataType>({
        query: AccountQuery.getInfo(),
        fetchPolicy: "no-cache",
      });
      accountInfo.value = data.CompanyData as Company;

      //   if (import.meta.env.VITE_APP_SENTRY == "TRUE") {
      //     Sentry.setUser({ email: this.accountInfo.value.email });
      //   }
    }

    return accountInfo.value as Company;
  };

  const authenticate = async (token: string) => {
    AuthService.setToken(token);
    let accountInfo = {} as Company;
    setTimeout(async () => {
      accountInfo = await getInfo(true);
    }, 500);

    return accountInfo;
  };

  const logout = async () => {
    AuthService.deleteToken();
    accountInfo.value = null;
    return await router.push({ name: "Login" });
  };

  const isAuthenticated = () => {
    return !!accountInfo.value || !!AuthService.getToken(); // Check if account info is available or if a token exists
  };

  return {
    accountInfo,
    authenticate,
    getInfo,
    logout,
    isAuthenticated,
  };
}
