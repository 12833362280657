import {
  BadRequestApiServiceError,
  CORSApiServiceError,
  UnauthorizedApiServiceError,
} from "@/services/ApiServiceError";
import type { ApolloError } from "@apollo/client";
import type { GraphQLFormattedError } from "graphql";
import type { ErrorResponse } from "@apollo/client/link/error";
import { KzFlashHandler } from "@keyfluenz/vue-core/dist/components/flash/KzFlashHandler";

export type HttpStatusCodeType = 400 | 401 | 404 | 405 | 500;
export type ResultErrorType = Record<string, unknown>;

export type ApiResponseErrorType = {
  statusCode: HttpStatusCodeType;
  result: ResultErrorType;
};

export class ApiErrorHandler {
  /**
   * Handles network errors by throwing specific exceptions based on the HTTP status code.
   * @param errorResponse - The Apollo error response.
   */
  static handle(errorResponse: ErrorResponse): void {
    const networkError = errorResponse.networkError as ApiResponseErrorType | null;

    if (!networkError) {
      return;
    }

    switch (networkError.statusCode) {
      case 401:
        throw new UnauthorizedApiServiceError("Unauthorized", networkError.result);
      case undefined:
        throw new CORSApiServiceError("CORS", errorResponse.networkError?.message);
      default:
        throw new BadRequestApiServiceError("BadRequest", networkError.result);
    }
  }

  /**
   * Handles GraphQL errors or specific network errors.
   * @param apolloError - The Apollo error instance.
   * @param callback - A function to handle GraphQL formatted errors.
   */
  static handleGraphQLError(
    apolloError: ApolloError,
    callback: (graphQLErrors: ReadonlyArray<GraphQLFormattedError>) => void
  ): void {
    if (apolloError.networkError) {
      const networkError = apolloError.networkError as ApiResponseErrorType;

      if (networkError.statusCode === 404) {
        ApiErrorHandler.handleNotFound();
        return;
      }
    }

    if (apolloError.graphQLErrors && apolloError.graphQLErrors.length > 0) {
      callback(apolloError.graphQLErrors);
    } else {
      //console.warn("Unhandled ApolloError:", apolloError);
    }
  }

  /**
   * Handles `404` network errors by displaying a user-friendly message.
   */
  private static handleNotFound(): void {
    KzFlashHandler.error("We currently cannot process your request [404]");
  }
}
