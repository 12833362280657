import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/",
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: { title: "wallet.meta", layout: "AuthenticatedLayout" },
    children: [
      {
        path: "wallet",
        name: "Wallet",
        component: () => import("@/modules/wallet/views/TheWallet.vue"),
      },
    ],
  },
];
