<script lang="ts" setup>
import { useToastStore } from "@/stores/ToastStore";
import { storeToRefs } from "pinia";
import type { KzComponentRenderProp } from "@keyfluenz/vue-core/dist/components/component-render/KzComponentRender.types";
import ExclamationIcon from "@/components/icons/ExclamationIcon.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";
import XMarkIcon from "@/components/icons/XMarkIcon.vue";
import CheckIcon from "@/components/icons/CheckIcon.vue";

defineOptions({
  name: "TheToast",
});

const toastStore = useToastStore();
const { toasts } = storeToRefs(toastStore);

const toastIcon = (type: string): KzComponentRenderProp => {
  if (type === "success") {
    return CheckIcon as KzComponentRenderProp;
  }
  if (type === "warning") {
    return ExclamationIcon as KzComponentRenderProp;
  }
  if (type === "error") {
    return XMarkIcon as KzComponentRenderProp;
  }
  return InfoIcon as KzComponentRenderProp;
};
</script>

<template>
  <div class="toast-container">
    <div
      v-for="toast in toasts"
      :key="toast.id"
      :class="['toast', toast.type, toast.visible ? 'opacity-100' : 'opacity-0']"
    >
      <div class="flex">
        <div class="toast-icon">
          <component :is="toastIcon(toast.type)" class="text-white" />
        </div>
        <span class="text-basic-700 text-sm">
          {{ toast.message }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.toast-container {
  @apply fixed bottom-20 right-5 z-50 space-y-2;

  .toast {
    @apply px-5 py-4 rounded-xl transition-opacity duration-500 min-h-[90px] w-[350px];

    .toast-icon {
      @apply rounded-lg p-2 mr-5 shadow w-8 h-8;
    }

    &.success {
      @apply bg-green-50 border border-green-500;

      .toast-icon {
        @apply bg-emerald-400 p-2.5;

        svg {
          @apply w-3 h-3;
        }
      }
    }

    &.error {
      @apply bg-red-50 border border-red-500;

      .toast-icon {
        @apply bg-red-500 p-2.5;

        svg {
          @apply w-3 h-3;
        }
      }
    }

    &.warning {
      @apply bg-yellow-50 border border-yellow-500;

      .toast-icon {
        @apply bg-yellow-500;

        svg {
          @apply w-4 h-4;
        }
      }
    }

    &.info {
      @apply bg-jordy-50 border border-jordy-500;

      .toast-icon {
        @apply bg-jordy-500;

        svg {
          @apply w-4 h-4;
        }
      }
    }
  }
}
</style>
