import { defineStore, storeToRefs } from "pinia";
import type { Message } from "@/modules/deal/queries/ChatQuery";
import { useMqttStore } from "@/stores/mqtt";
import useAccountHandler from "@/helpers/useAccountHandler";
import { ref, watch } from "vue";
import { useToastStore } from "@/stores/ToastStore";
import type { Company } from "@/modules/campaign/queries/CampaignQuery.types";

export type MqttResponse = {
  topic: string;
  message: Message;
};

export type Notification = {
  id: string;
  message: string;
  company_id: string;
  creator_id: string;
  deal_id: string;
  deal_content_id: string;
  tip_id: string;
};

export const useNotificationsStore = defineStore("notifications", () => {
  const { isAuthenticated, getInfo } = useAccountHandler();
  const mqttStore = useMqttStore();
  const { mqttResponse } = storeToRefs(mqttStore);
  const toastStore = useToastStore();
  const message = ref("");

  const company = ref<Company>({} as Company);

  const initializeSubscription = async () => {
    if (isAuthenticated()) {
      company.value = await getInfo(true);
      mqttStore.subscribeToTopic(`notifications/company/${company?.value.id}`);
    }
  };

  watch(mqttResponse, (value) => {
    if (value) {
      //console.log(value, " asdfasdfasdfasdf");
      toastStore.info(value.message.message);
    }
  });

  return {
    initializeSubscription,
    mqttResponse,
  };
});
