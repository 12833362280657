import gql from "graphql-tag";

export default class AccountQuery {
  static signup() {
    return gql`
      mutation CompanyCreate($email: String!, $password: String!) {
        CompanyCreate(email: $email, password: $password) {
          message
          status
        }
      }
    `;
  }

  static login() {
    return gql`
      mutation CompanyLogin($email: String!, $password: String!) {
        CompanyLogin(email: $email, password: $password) {
          status
          authorization {
            token
            type
            expires_in
          }
        }
      }
    `;
  }

  static getInfo() {
    return gql`
      query CompanyData {
        CompanyData {
          id
          first_name
          last_name
          email
          role
          has_verified_email
          onboard_position
          phone
          company_name
          bank_transfer_information {
            id
            company_id
            full_name
            account_number
            routing_number
            account_type
            bank_address
            recipient_name
            purpose_of_payment
            created_at
          }
          wallet {
            user_id
            balance
            currency {
              id
              name
              symbol
              code
            }
          }
        }
      }
    `;
  }

  static onBoardStep1() {
    return gql`
      mutation CompanyOnboardPositionOne(
        $company: String!
        $name: String!
        $lastName: String!
        $phone: String
        $areaCode: String
      ) {
        CompanyOnboardPositionOne(
          company_name: $company
          first_name: $name
          last_name: $lastName
          phone: $phone
          area_code: $areaCode
        ) {
          status
          message
        }
      }
    `;
  }

  static resendVerificationCode() {
    return gql`
      mutation CompanyResendEmailVerificationCode {
        CompanyResendEmailVerificationCode {
          status
          message
        }
      }
    `;
  }

  static verificationCode() {
    return gql`
      mutation CompanyEmailVerificationByCode($code: String!) {
        CompanyEmailVerificationByCode(email_validation_code: $code) {
          status
          message
        }
      }
    `;
  }

  static requestResetPassword() {
    return gql`
      mutation RequestResetPassword($email: String) {
        RequestResetPassword(email: $email, type: "company") {
          status
          message
        }
      }
    `;
  }

  static resetPassword() {
    return gql`
      mutation ResetPasswordMutation(
        $email: String
        $token: String
        $password: String
        $confirmPassword: String
      ) {
        ResetPasswordMutation(
          email: $email
          token: $token
          password: $password
          password_confirmation: $confirmPassword
        ) {
          status
          message
        }
      }
    `;
  }

  static updateInformation() {
    return gql`
      mutation CompanyUpdateData(
        $email: String
        $privacy: Boolean
        $name: String
        $password: String
        $newPassword: String
        $confirmNewPassword: String
      ) {
        CompanyUpdateData(
          new_email: $email
          privacy: $privacy
          company_name: $name
          password: $password
          new_password: $newPassword
          confirm_password: $confirmNewPassword
        ) {
          status
          message
        }
      }
    `;
  }

  static deleteAccount() {
    return gql`
      mutation CompanyDelete {
        CompanyDelete {
          status
          message
        }
      }
    `;
  }

  static requestEmailUpdate() {
    return gql`
      mutation CompanyRequestToChangeEmail($email: String, $newEmail: String, $password: String) {
        CompanyRequestToChangeEmail(email: $email, new_email: $newEmail, password: $password) {
          status
          message
        }
      }
    `;
  }

  static updateEmail() {
    return gql`
      mutation CompanyUpdateEmail($password: String, $token: String!, $email: String!) {
        CompanyUpdateEmail(password: $password, token: $token, old_email: $email) {
          status
          authorization {
            token
            type
            expires_in
          }
        }
      }
    `;
  }
}
