<script lang="ts" setup>
import { ViewColumnsIcon } from "@heroicons/vue/24/outline";
import TopFluenzIcon from "@/components/icons/TopFluenzIcon.vue";
import { KzAvatar, KzFlyout } from "@keyfluenz/vue-core";
import TFIcon from "@/components/icons/TFIcon.vue";
import { computed, onMounted, ref } from "vue";
import useAccountHandler from "@/helpers/useAccountHandler";
import NavigationElement from "./NavigationElement.vue";
import type { NavigationElementDetail } from "@/components/core/navigation/NavigationElement.vue";
import { useNavigationHelper } from "@/components/core/navigation/composable/useNavigationHelper";

defineOptions({
  name: "NavigationSidebar",
});

const { accountInfo, getInfo, logout } = useAccountHandler();

const collapsed = ref(false);
const { navigation } = useNavigationHelper();

const solutions = [{ name: "Log out" }];

const collapseElement = {
  name: "Collapse sidebar",
  to: "toggle",
  icon: ViewColumnsIcon,
  current: false,
  type: "button",
} as NavigationElementDetail;

const nameInfo = computed((): string => {
  const profileImage = accountInfo.value?.profile_picture_image;
  const firstName = accountInfo.value?.first_name;

  return profileImage && profileImage.trim() !== "" ? profileImage : firstName || "A";
});

const fullName = computed(() => {
  return `${accountInfo.value?.first_name} ${accountInfo.value?.last_name}`;
});

const emailInfo = computed((): string => {
  return accountInfo.value?.email || "";
});

onMounted(async () => {
  if (!accountInfo.value) {
    await getInfo();
  }
});

function toggle() {
  collapsed.value = !collapsed.value;
}

function onAction(action: string) {
  if (action === "toggle") {
    toggle();
  }
}
</script>

<template>
  <aside :class="['navigation-sidebar', { collapsed: collapsed }]">
    <div v-if="!collapsed" class="navigation-sidebar-logo">
      <TopFluenzIcon class="w-28" />
    </div>
    <div v-else class="navigation-sidebar-logo">
      <TFIcon class="w-12 my-[19px]" />
    </div>
    <nav class="navigation-sidebar-menu">
      <ul class="flex flex-1 flex-col gap-y-3 mb-2" role="list">
        <li v-for="item in navigation" :key="item.name">
          <NavigationElement :collapsed="collapsed" :element="item" />
        </li>
        <li class="mt-auto">
          <NavigationElement :collapsed="collapsed" :element="collapseElement" @action="onAction" />
        </li>
      </ul>
    </nav>
    <div class="divider" />
    <div class="navigation-sidebar-user">
      <div class="user-menu">
        <KzFlyout data-testid="flyout-avatar">
          <template #button>
            <div class="flex space-x-4 items-start max-w-52 group">
              <KzAvatar :name="nameInfo" />
              <div v-if="!collapsed" class="w-40">
                <p class="user-menu-name">{{ fullName }}</p>
                <p class="user-menu-email">{{ emailInfo }}</p>
              </div>
            </div>
          </template>
          <template #items>
            <button
              v-for="item in solutions"
              :key="item.name"
              class="p-2 hover:text-blue-600"
              @click="logout"
            >
              {{ item.name }}
            </button>
          </template>
        </KzFlyout>
      </div>
    </div>
  </aside>
</template>

<style lang="scss" scoped>
.navigation-sidebar {
  @apply top-0 left-0 flex flex-col w-[260px] bg-white border-r border-neutral-200 px-4 transition duration-700 ease-in-out;

  &-logo {
    @apply mx-auto py-12 transition duration-700 ease-in-out;
  }

  &-menu {
    @apply flex flex-1 overflow-auto;
  }

  &.collapsed {
    @apply w-[108px] flex justify-center bg-white items-center transition duration-700 ease-in-out;

    .navigation-sidebar-user {
      .user-menu {
        @apply m-2 hover:bg-sunburst-300 rounded-full h-12 w-12 flex items-center justify-center;
      }
    }

    :deep(.menu-element) {
      .navigation-icon {
        @apply mx-3 text-jordy-700 hover:text-jordy-700 transition duration-700 ease-in-out;
      }

      &:hover {
        @apply bg-jordy-700;

        .navigation-icon {
          @apply text-white;
        }
      }

      &.current {
        @apply bg-jordy-700;

        .navigation-icon {
          @apply text-white;
        }
      }
    }

    :deep(.avatar) {
      @apply w-10 h-10 duration-700 ease-in-out;
    }

    .divider {
      @apply border-jordy-600;
    }
  }

  @media (max-width: 1024px) {
    position: absolute;
    z-index: 99;
  }

  &-user {
    @apply -mx-4 relative;

    .user-menu {
      @apply flex items-center gap-x-4 px-4 py-3;

      &-name {
        @apply text-start text-sm font-semibold text-jordy-700 hover:text-white break-all line-clamp-1;
      }

      &-email {
        @apply text-start text-xs text-jordy-700 hover:text-white break-all line-clamp-1;
      }

      &:hover {
        @apply hover:bg-jordy-700;

        .user-menu-name,
        .user-menu-email {
          @apply text-white;
        }
      }
    }
  }

  .divider {
    @apply border-b border-jordy-300 w-full mb-6;
  }
}

:deep(.flyout__panel) {
  @apply translate-x-0;
}

:deep(.flyout__panel__item) {
  @apply bg-red-100;
}
</style>
