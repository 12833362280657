import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import AuthService from "./AuthService";
import useAccountHandler from "./useAccountHandler";
import type { OnboardPositionType } from "@/types/AccountTypes";
import { initGtm } from "@/plugins/gtm";

type SequenceType = {
  [key in OnboardPositionType]: string;
};

export default class AuthGuard {
  static withoutToken(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): void {
    if (AuthService.isAuthenticated()) {
      return next({
        name: "Dashboard",
      });
    }
    return next();
  }

  static withToken(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): void {
    if (AuthService.isAuthenticated()) {
      return next();
    }

    return next({
      name: "Login",
    });
  }

  static async withEmailUnverified(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (!AuthService.isAuthenticated()) {
      return next({
        name: "Login",
      });
    }

    const { accountInfo, getInfo } = useAccountHandler();
    if (accountInfo.value === null) {
      await getInfo();
    }

    if (!accountInfo.value) {
      return next({
        name: "Login",
      });
    }

    if (accountInfo.value.has_verified_email) {
      return next({
        name: "AboutYou",
      });
    }

    next();
  }

  static async withCompleteOnboarding(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (!AuthService.isAuthenticated()) {
      return next({
        name: "Login",
      });
    }

    const { accountInfo, getInfo } = useAccountHandler();
    if (accountInfo.value === null) {
      await getInfo();
    }

    if (!accountInfo.value) {
      return next({
        name: "Login",
      });
    }

    if (!accountInfo.value.has_verified_email) {
      return next({
        name: "Verify",
      });
    }

    const position = accountInfo.value.onboard_position;
    const sequence: SequenceType = {
      0: "AboutYou",
    };

    if (Reflect.has(sequence, position)) {
      return next({
        name: Reflect.get(sequence, position),
      });
    }
    to.meta.from = from.name;
    await initGtm();

    next();
  }

  static async withIncompleteOnboarding(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (!AuthService.isAuthenticated()) {
      return next({
        name: "Login",
      });
    }

    const { accountInfo, getInfo } = useAccountHandler();
    if (accountInfo.value === null) {
      await getInfo();
    }

    if (!accountInfo.value) {
      return next({
        name: "Login",
      });
    }

    if (!accountInfo.value.has_verified_email) {
      return next({
        name: "Verify",
      });
    }

    const position = accountInfo.value.onboard_position;

    if (position === 1) {
      return next({
        name: "Dashboard",
      });
    }

    next();
  }

  static async withImpersonation(to: RouteLocationNormalized) {
    const { authenticate } = useAccountHandler();
    await authenticate(to.params.token.toString());
  }
}
