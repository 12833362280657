import useAccountHandler from "@/helpers/useAccountHandler";
import { useGtm } from "vue-gtm";
import { watch } from "vue";
import type { Company } from "@/modules/campaign/queries/CampaignQuery.types";

export const initGtm = () => {
  const { accountInfo, getInfo } = useAccountHandler();
  const gtm = useGtm();

  const sendEvent = async () => {
    const account = (await getInfo(true)) as Company;
    if (gtm) {
      gtm.trackEvent({
        event: "boot",
        user_id: `CO/${account?.id}`,
        name: `${account?.first_name} ${account?.last_name}`,
        created: account?.created_at,
        email: account?.email,
        custom_attributes: {
          role: "company",
        },
      });

      // Update Intercom with the new user data
      if (window.Intercom) {
        window.Intercom("update", {
          user_id: `CO/${account?.id}`,
          email: account?.email,
          name: `${account?.first_name} ${account?.last_name}`,
          created_at: account?.created_at,
          custom_attributes: {
            role: "company",
          },
        });
      }
    }
  };

  sendEvent();

  watch(
    accountInfo,
    (account) => {
      if (account) {
        //sendEvent();
      }
    },
    { deep: true }
  );
};
